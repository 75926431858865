
import { Component, Vue } from "vue-property-decorator";
import { getProductById, getPictureByProductid } from "../api/ApiPath";
@Component
export default class itemDetail extends Vue {
  public flag: boolean = false;
  public showing = "";
  dataNum = 1;
  productPicture = [];
  productPictureDetail = [];
  productDetail = {
    product_id: 0,
    product_name: "a",
    category_id: 0,
    product_title: "",
    product_intro: "",
    product_picture:"",
    product_price: 0,
    product_num: 0,
    product_sales: 0,
    product_details:"",
  };
  num = 1;
  listItems = [
    {
      id: 1,
      pic: require("../assets/04.jpg"),
      content: "フェイスシールド",
      price: 13000,
    },
    {
      id: 2,
      pic: require("../assets/04.jpg"),
      content: "フェイスシールド",
      price: 13000,
    },
    {
      id: 3,
      pic: require("../assets/04.jpg"),
      content: "フェイスシールド",
      price: 13000,
    },
    {
      id: 4,
      pic: require("../assets/04.jpg"),
      content: "フェイスシールド",
      price: 13000,
    },
    {
      id: 5,
      pic: require("../assets/04.jpg"),
      content: "フェイスシールド",
      price: 13000,
    },
  ];
  itemList = {
    id: 3,
    name: "LDPE",
    img_url: require("../assets/images/ldpe1.jpg"),
    price: "80000",
    property: [
      {
        name: "メーカー",
        value:
          "パウダーフリー(粉なし)、薄手タイプなので長時間細やかな作業に適している使い捨て手袋、便利な左右兼用タイプ",
      },
      { name: "*注意", value: "調理用には、絶対使用しないでください" },
      { name: "サイズ", value: "フリーサイズ(男女兼用)" },
      { name: "内容量", value: "100枚入り" },
      { name: "生産国", value: "CHN" },
      { name: "成分", value: "ポリエチレン" },
    ],
  };

  startimg() {
    this.flag = true;
  }
  cancelimg() {
    this.flag = false;
  }

  addCart() {
    this.$router.push({ path: "/trolley" });
  }

    // 计算属性，确保返回数组
  // get normalizedProductPicture(): Array<{ name: string; url: string }> {
  //   if (Array.isArray(this.productDetail.product_picture)) {
  //     return this.productDetail.product_picture;
  //   }
  //     // 如果是字符串类型，尝试解析 JSON
  // if (typeof this.productDetail.product_picture === 'string') {
  //   try {
  //     const parsed = JSON.parse(this.productDetail.product_picture);
  //     if (Array.isArray(parsed)) {
  //       return parsed;
  //     }
  //   } catch (error) {
  //     console.log('Failed to parse product_picture:', error);
  //   }
  // }
  //   console.log('product_picture is not an array, returning empty array');
  //   return [];
  // }
  
  getThumb(photo) {
      let thumb = require("../assets/" + photo);
      return thumb;
  }
  changeJson(jsonStr) {
    let jsonObj = JSON.parse(jsonStr);
    return jsonObj;
  }
  //获得该用户信息
  mounted() {
    let { product_id } = this.$route.query;
    getProductById(
      { product_id },
      (res:any) => {
        const { status, product, msg } = res;
        if (status == 200) {
          this.productDetail = product[0];
          console.log(this.productDetail);
        } else {
          this.$message.error(msg);
        }
      },
      (err:any) => {
        this.$message.error(err);
      }
    ),

    getPictureByProductid(
      { product_id, isMain:0 },
      (res:any) => {
        const { status, data, msg } = res;
        if (status == 200) {
          this.productPicture = data;
        } else {
          this.$message.error(msg);
        }
      },
      (err:any) => {
        this.$message.error(err);
      }
    )
    getPictureByProductid(
      { product_id, isMain:1 },
      (res:any) => {
        const { status, data, msg } = res;
        if (status == 200) {
          this.productPictureDetail = data;
        } else {
          this.$message.error(msg);
        }
      },
      (err:any) => {
        this.$message.error(err);
      }
    )
  }
}
